<template>
    <!-- <template #title>
      <div class="title">关联用户</div>
    </template>
    <template #footer>
      <el-button @click="resetSelectList">重置</el-button>
      <el-button type="primary"
        @click="submitData">确 定</el-button>
    </template> -->
    <div class="mian-container">
      <!-- 头部搜索 -->
      <!-- <div class="header">
        <el-select class="header-search"
          @change="pickerChange"
          placeholder="请选择"
          v-model="selectPickerItem"
          :disabled="topPickerData.length < 2">
          <el-option v-for="(tp, index) in topPickerData"
            :key="index"
            :label="tp.label"
            :value="tp.value"></el-option>
        </el-select>
      </div> -->
      <!-- 穿梭框 -->
      <div class="transfer">
        <!-- 左 -->
        <div class="transfer-left" v-if="!isShowOnly">
          <!-- 左边头部 -->
          <div class="left-top transfer-top">
            <div class="text-top">
              <span>选择方案</span>
              <span>({{ treeSelectCount + selectCount }})</span>
            </div>
            <div class="text-top">
              <el-checkbox v-model="notRelatedChecked"
                @change="changeRelatedChecked"></el-checkbox>
              <span>未关联方案</span>
            </div>
          </div>
          <!-- 方案搜索 -->
          <div>
            <el-input placeholder="请输入方案名"
              suffix-icon="el-icon-search"
              v-model="filterText" />
          </div>

          <!-- 树形结构 -->
          <div>
            <el-tree class="left-tree"
              show-checkbox
              :data="treeData"
              :node-key="defaultProps.uuidName"
              :filter-node-method="filterNode"
              :default-checked-keys="checkedNodes"
              :props="defaultProps"
              ref="structureTree"
              @check="selectNode">
              <span slot-scope="{ node, data }">
                <span>
                  {{ node.label
                  }}
                  <el-tag v-if="
                      data[defaultProps.typeName] ==
                      defaultProps[defaultProps.typeName]
                    ">方案</el-tag>
                  <el-tag v-else
                    type="success">公司</el-tag>
                </span>
              </span>
            </el-tree>
          </div>
        </div>
        <i class="el-icon-sort middle-icon" v-if="!isShowOnly"></i>
        <!-- 右 -->
        <div class="transfer-right">
          <div class="right-top transfer-top" v-if="!isShowOnly">
            <div class="text-top">
              <span>已选</span>
              <span>{{ selectCount }}</span>
            </div>
            <div class="clear">
              <span @click="clearNode">清空</span>
            </div>
          </div>
          <!-- 选中列表 -->
          <div class="select-list">
            <div class="select-item"
              v-for="(person, index) in selectList"
              :key="index"
              @mouseenter="inSelectItem(index)"
              @mouseleave="outSelectItem(index)">
              <span>{{ person[defaultProps.label] }}</span>
              <i @click="removeSelect(index, person[defaultProps.uuidName])" v-if="!isShowOnly"
                class="el-icon-circle-close"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: {
    treeDialogVisible: {
      type: Boolean,
      default: false,
    },
    treeData: {
      type: Array,
      default: () => [],
    },
    treeSelectNodeList: {
      type: Array,
      default: () => [],
    },
    treeSelectKeyList: {
      type: Array,
      default: () => [],
    },
    //头部选择下拉框字典[]
    topPickerData: {
      type: Array,
      default: () => [],
    },
    defaultProps: {
      type: Object,
      default: () => {
        return {
          children: "children",
          label: "label",
          disabled: "disabled",
          type: undefined || null || "",
          typeName: "type",
          uuidName: "value",
        };
      },
    },
    //是否在右边展示之前选的数据
    showOldSelectNode: {
      type: Boolean,
      default: false,
    },
    //特殊id,此组件获取树的id
    dataId: {
      type: String,
      default: "",
    },
    isShowOnly: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      show: false,
      filterText: "",
      filterCondition: {},
      selectPickerItem: 0,
      selectList: [],
      notRelatedChecked: false,
    };
  },
  watch: {
    filterText(value) {
      this.$refs.structureTree.filter({ value });
    },
    treeDialogVisible(n) {
      this.show = n;
    },
    show(n) {
      this.$emit("update:treeDialogVisible", n);
    },
    treeSelectNodeList: {
      immediate: true,
      handler(n) {
        if (this.showOldSelectNode) {
          this.selectList = [...this.selectList, ...n];
        }
      },
    },
    treeSelectKeyList: {
      deep: true,
      handler(n) {
        if (this.showOldSelectNode) {
          this.selectList = [...this.selectList, ...n];
          this.$nextTick(() => {
            this.$refs.structureTree
              ? this.$refs.structureTree.setCheckedKeys(n.map((val) => val[this.defaultProps.uuidName]))
              : "";
          });
        }
      },
    },
  },
  computed: {
    selectCount() {
      return this.selectList.length || 0;
    },
    treeSelectCount() {
      if (!this.dataId) return 0;
      let count = 0;
      this.treeSelectNodeList.map((item) => {
        if (this.dataId == item.parentId) {
          count++;
        }
      });
      return count;
    },
    checkedNodes() {
      let checkedArray = [];
      this.treeSelectNodeList.map((item) => {
        checkedArray.push(item[this.defaultProps.uuidName]);
      });
      return checkedArray;
    },
  },
  methods: {
    filterNode({ value }, data) {
      //if (!value) return true;
      if (this.notRelatedChecked) {
        return (
          data[this.defaultProps.label].indexOf(value) !== -1 &&
          !data[this.defaultProps.disabled]
        );
      } else {
        return data[this.defaultProps.label].indexOf(value) !== -1;
      }
    },
    inSelectItem(index) {
      //this.selectList[index].showDelet = true;
    },
    outSelectItem(index) {
      //this.selectList[index].showDelet = false;
    },
    selectNode(data, { checkedNodes }) {
      this.selectList = [];
      checkedNodes.map((node) => {
        if (
          this.selectList.some((n) => {
            return n[this.defaultProps.uuidName] == node[this.defaultProps.uuidName];
          })
        ) {
          // this.$message({
          //   type: "warning",
          //   message: "此用户已经添加到本部门！",
          // });
          return;
        }
        if (
          node[this.defaultProps.typeName] ==
          this.defaultProps[this.defaultProps.typeName]
        ) {
          if (!this.showOldSelectNode) {
            if (!node[this.defaultProps.disabled]) {
              this.selectList.push(node);
            }
          } else {
            this.selectList.push(node);
          }
        }
      });
    },
    removeSelect(index, treeId) {
      this.$refs.structureTree.setChecked(treeId, false, true);
      this.selectList.splice(index, 1);
    },
    //获取选中列表数组
    getTreeSelectNodeList() {
      return this.selectList;
    },
    //数据提交
    submitData() {
      return this.selectList || [];
      // if (this.selectList.length) {
      //   this.$emit("submitData", this.selectList);
      // }else {
      //   this.$emit("submitVacancyData");
      // }
      // this.show = false;
    },
    changeRelatedChecked() {
      this.$refs.structureTree.filter({ value: this.filterText });
    },
    //还原选择的节点
    resetSelectList() {
      this.clearNode();
      if (this.showOldSelectNode) {
        this.selectList = [...this.treeSelectNodeList];
        if (this.treeSelectKeyList && this.treeSelectKeyList.length) {
          this.selectList = [...this.treeSelectKeyList];
          this.$nextTick(() => {
            this.$refs.structureTree
              ? this.$refs.structureTree.setCheckedKeys(
                this.treeSelectKeyList.map((val) => val[this.defaultProps.uuidName])
              )
              : "";
          });
        }
      }
      this.filterText = "";
      this.notRelatedChecked = false;
      //this.$emit("update:treeData", this.treeData);
    },
    //选择框数据变化
    pickerChange(val) {
      this.$emit("pickerChange", val);
    },
    //清空选择的节点
    clearNode() {
      this.selectList.forEach((node) => {
        if (!node[this.defaultProps.disabled]) {
          this.$refs.structureTree.setChecked(
            node[this.defaultProps.uuidName],
            false,
            true
          );
        }
      });
      this.selectList = [];
    },
    closeDialog() {
      this.clearNode();
      this.notRelatedChecked = false;
      this.filterText = "";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  background-color: #4b7bca;
  border-radius: 10px 10px 0 0;
}
::v-deep .el-dialog {
  border-radius: 10px;
}
.title {
  display: flex;
  justify-content: left;
  color: #ffffff;
}
.mian-container {
  display: column;
  .header {
    .header-search {
      width: 100%;
    }
  }
  .transfer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    .middle-icon {
      color: #4278c9ff;
      align-self: center;
      font-size: 20px;
      transform: rotate(90deg);
    }
    > div {
      padding: 10px;
      margin: 0 5px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #cccccc;
    }
    .transfer-left {
      flex: 1;
      max-height: max-content;
      //overflow: scroll;
      .left-top {
        margin-bottom: 5px;
        //   position: sticky;
        //   top: 50;
      }
      .left-tree {
        height: 200px;
        overflow: scroll;
      }
    }
    .transfer-right {
      display: column;
      flex: 1;
      max-height: max-content;
      //overflow: auto;
      .right-top {
        .clear {
          color: #4278c9ff;
        }
      }
      .select-list {
        display: column;
        margin: 5px 0;
        height: 240px;
        overflow: scroll;
        .select-item {
          display: flex;
          padding: 5px;
          justify-content: space-between;
          align-items: center;
          .el-icon-circle-close {
            display: none;
          }
        }
        .select-item:hover {
          background: #bbcfee;
          border-radius: 4px;
          .el-icon-circle-close {
            display: inline;
          }
        }
      }
    }
  }
  .transfer-top {
    display: flex;
    justify-content: space-between;
    background-color: #ffffffff;
    padding: 5px;
    border-radius: 5px;
  }
  .text-top {
    > span {
      margin: 0 5px;
    }
  }
}
</style>
