<template>
  <div class="container">
    <GeneralTable ref="GeneralTable"
      :dataList="claimRecordList"
      :column="column"
      :option="option"
      :totalNum="totalNum"
      @search-change="getList"
      @del-change="deleteItem"
      @export-data="exportData"
      tableName="DistributionAgentAudit"
      >
      <template slot="processStatusDesc"
        slot-scope="{item}">
        {{ item.processStatusDesc }}
      </template>
      <template slot="cardFileUrl"
        slot-scope="{item}">
        <el-image
            style="width: 32px; height: 32px;border-radius: 50%;"
            :src="item.cardFileUrl" :preview-src-list="[item.cardFileUrl]"
            fit="cover"></el-image>
      </template>
      <template slot="operate"
        slot-scope="{item}">
        <!-- handleHasPerms('M22-role-edit') &&  -->
        <el-button
          @click.native.prevent="auditItem(item, false)"
          v-if="item.processStatus == 0" :disabled="item.superiorId != $store.state.userInfo.MJUserId && handleHasPerms('M31-audit-edit')"
          type="text"
          size="small">审核</el-button>
        <el-button
          @click.native.prevent="auditItem(item, true)"
          v-else-if="handleHasPerms('M31-audit-edit')"
          type="text"
          size="small">详情</el-button>
      </template>
    </GeneralTable>
    <auditDialog v-model="isAddVisible" :isDisabled="isDisabled"
      :auditReason="editData"
      @changeList="changeList" />
  </div>
</template>

<script>
import GeneralTable from "@/components/GeneralTable/index.vue";
import auditDialog from "@/views/DistributionManagement/components/auditDialog.vue";
import { agentUserPageOfSH, deleteOfSH, getRelateTreeData, roleBindUser, listGrantedUser, exportExcelOfSH } from "@/api/SystemData";
import { hasPerms } from "@/utils/auth";
export default {
  name: 'DistributionAgentAudit',
  data() {
    return {
      option: {
        isAdd: false, //添加 事件 @add-change
        isDel: hasPerms('M31-audit-del'), //删除 @del-change
        isExport: hasPerms("M31-audit-edit"),
        isSearch: true, // 搜索 @search-change
        isRefresh: true, // 刷新 @search-change
        pageSize: 10,
        rowPlaceholder: '--',  // 列表数据为空时的显示
        searchList: [{
          label: '代理人名称',
          prop: 'realName',
          formType: 'input',
          maxLength: 11,
          clearable: true,
          placeholder: "请输入"
        }, {
          label: '代理人类型',
          prop: 'customerType',
          formType: 'select',
          filterable: true,
          // propValue: 1,
          placeholder: "请选择",
          selectList: [
            { value: '', text: '所有' },
            { value: 1, text: '企业' },
            { value: 2, text: '个人' },
          ]
        },
        {
          label: '联系方式',
          prop: 'concat',
          formType: 'input',
          maxLength: 11,
          clearable: true,
          placeholder: "请输入"
        }, {
          label: '注册时间',
          formType: 'daterange',
          clearable: true,
            format: 'yyyy.MM.dd',
          prop: ['createTimeBegin', 'createTimeEnd']
        }, 
        {
          label: '证件号码',
          prop: 'cardNo',
          formType: 'input',
          maxLength: 32,
          clearable: true,
          placeholder: "请输入",
        }, {
          label: '层级',
          prop: 'rank',
          formType: 'select',
          filterable: true,
          placeholder: "请选择类型",
          selectList: [
            { value: '', text: '全部' },
            { value: 1, text: '一级' },
            { value: 2, text: '二级' },
          ],
        },
        {
          label: '上级',
          prop: 'superiorName',
          formType: 'input',
          maxLength: 32,
          clearable: true,
          placeholder: "请输入",
        },{
          label: '状态',
          prop: 'processStatus',
          formType: 'select',
          filterable: true,
          placeholder: "请选择类型",
          selectList: [
            { value: '', text: '全部' },
            { value: '0', text: '待审核' },
            { value: 1, text: '已审核' },
            { value: 2, text: '已退回' },
          ]
        }, 
        {
          label: '业务员',
          prop: 'salesmanName',
          formType: 'input',
          maxLength: 32,
          clearable: true,
          placeholder: "请输入"
        }]
      },
      column: [
        {
          label: '序号',
          type: 'index',
          isShow: true,
          fixed: 'left'
        },
        {
          label: "审核编号",
          prop: "processNo",
          align: 'center',
          isShow: true,
          width: 180
        },
        {
          label: "姓名",
          prop: "realName",
          align: 'center',
          isShow: true,
        },
        {
          label: "联系方式",
          prop: "concat",
          align: 'center',
          isShow: true,
          width: 140
        },
        {
          label: "类型",
          prop: "customerTypeDesc",
          align: 'center',
          isShow: true,
        },
        {
          label: "企业名称",
          prop: "companyName",
          align: 'center',
          isShow: true,
          width: 140
        },
        {
          label: "证件号码",
          prop: "cardNo",
          align: 'center',
          isShow: true,
          width: 180
        },
        {
          label: "证件照片",
          prop: "cardFileUrl",
          align: 'center',
          width: '100',
          isShow: true,
          isSlot: true,
        },
        {
          label: "层级",
          prop: "rankDesc",
          align: 'center',
          isShow: true,
        },
        {
          label: "上级",
          prop: "superiorName",
          align: 'center',
          isShow: true,
        },
        {
          label: "所属业务员",
          prop: "salesmanName",
          align: 'center',
          isShow: true,
          width: 120
        },
        {
          label: "审核状态",
          prop: "processStatusDesc",
          align: 'center',
          isShow: true,
          isSlot: true,
        },
        {
          label: "注册时间",
          prop: "createTime",
          align: 'center',
          isShow: true,
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          align: 'center',
          width: 100,
          fixed: 'right',
          isSlot: true,
        },
      ],
      //数据总量
      totalNum: 0,
      claimRecordList: [],
      operateTitle: '添加',
      isAddVisible: false,
      editData: {},
      isByUserVisible: false,
      roleId: '',
      showTreeDialog: false,
      relateTree: [],
      treeSelectKeyList: [],
      treeProps: {
        children: "children",
        label: "title",
        disabled: "selected",
        //员工标识
        type: 2,
        //标识名
        typeName: "type",
        uuidName: "id",
      },
      topPickerData: [],
      oldInterfaceData: {},
      isDisabled: false
    };
  },
  components: { GeneralTable, auditDialog },
  created() { },
  watch: {
  },
  methods: {
    //获取数据
    async getList(v, pageNum, pageSize) {
      this.oldInterfaceData = { ...v, current: pageNum, size: pageSize, fastLike: v.keyword, createTimeBegin: v.createTimeBegin ? v.createTimeBegin + ' 00:00:00' : '', createTimeEnd: v.createTimeEnd ? v.createTimeEnd + ' 23:59:59' : '' }
      const { code, data } = await agentUserPageOfSH(this.oldInterfaceData);
      
      if (code === 200) {
        this.claimRecordList = data.records;
        this.totalNum = data.total;
      }
    },
    changeList() {
      this.$refs.GeneralTable.getList();
    },
    // 删除
    deleteItem(e) {
      if (e && e.length) {
        // const flag = e.findIndex(val => val.grantAccountCount > 0);
        // if (flag > -1) {
        //   this.$message({
        //     type: 'warning',
        //     message: '请先移除该记录!'
        //   });
        //   return false;
        // }
        this.$confirm('确认删除该记录?', '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let ids = e.map(val => val.id).toString();
          deleteOfSH({
            ids
          }).then(res => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.changeList();
          }).catch(err => {
            this.$message({
              type: 'error',
              message: '失败!'
            });
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      } else {
        this.$message.error('请选择需要删除的记录！');
      }
    },
    // 编辑
    auditItem(e, flag) {
      this.editData = e;
      this.isDisabled = flag;
      this.isAddVisible = true;
    },
    // 跳页面
    handleGoPath(path, item) {
      let ids = '';
      let name = '';
      let tenantId = '';
      if (item) {
        ids = item.id;
        name = item.roleName;
        tenantId = item.tenantId;
      } else if (this.$refs.GeneralTable.tableSelectionList && this.$refs.GeneralTable.tableSelectionList.length) {
        ids = this.$refs.GeneralTable.tableSelectionList.map(val => val.id).toString();
        name = this.$refs.GeneralTable.tableSelectionList.map(val => val.roleName).toString();
      } else {
        this.$message.warning('请选择需要设置的角色！');
        return false;
      }
      this.$router.push({
        path: `${ path }/${ ids }/${ name }/${ tenantId }`,
      });
    },
    exportData(e) {
      // if (!e || !e.length) {
      //   this.$message.warning('请选择需要导出的角色！');
      //   return false;
      // }
      exportExcelOfSH({
         ...this.oldInterfaceData,
        roleIds: e.map(val => val.id).toString()
      });
    },
    async handleAssociation(item) {
      this.editData = item;
      let userIds = await listGrantedUser({ roleId: item.id });
      let treeData = await getRelateTreeData({
        tenantId: item.tenantId
      });
      if (treeData.data && treeData.data.deptVOS) {
        this.relateTree = treeData.data.deptVOS;
      }
      if (userIds.data && userIds.code == 200) {
        this.treeSelectKeyList = userIds.data.map(val => {
          return {
            title: val.name,
            id: val.id
          };
        });
      }
      this.topPickerData = [{ label: item.roleName, value: 0 }];
      this.showTreeDialog = true;
      //   .then(res => {
      //   console.log(res);
      //   res.data && res.data.deptVOS ? this.relateTree = res.data.deptVOS : '';
      //   res.data && res.data.userIds ? this.treeSelectNodeList = res.data.userIds : '';
      // });
    },
    submitRelateUser(e) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      roleBindUser({
        roleId: this.editData.id,
        userIds: e.map(val => val.id)
      }).then(res => {
        this.changeList();
        loading.close();
      }).catch(err => {
        loading.close();
        console.log(err);
      });
    },
    handleHasPerms(e) {
      return hasPerms(e);
    }

  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 12px;
  .policy-form-box {
    display: flex;
    background: #ffffff;
    padding: 12px;
    padding-bottom: 0;
    border-radius: 8px;
  }
  .policy-table {
    flex: 1;
    margin-top: 12px;
    background: #ffffff;
    padding: 12px;
    border-radius: 8px;
    .polecy-button + .polecy-button {
      margin: 0;
    }
    .polecy-button {
      position: relative;
      padding: 12px;
    }
    .polecy-button::after {
      content: '';
      position: absolute;
      right: 0;
      top: 12px;
      bottom: 12px;
      width: 1px;
      background-color: #cccccc;
    }
    .polecy-button-no::after {
      display: none;
    }
    .table-button {
      display: flex;
      justify-content: space-between;
      margin: 12px;
      margin-bottom: 0;
      padding-bottom: 12px;
      border-bottom: 1px dashed #cccccc;
      &-right {
        display: flex;
      }
    }
  }
}
</style>
<style lang="scss">
.container {
  padding: 12px;
  .policy-form-box {
    .el-form-item__content {
      width: 220px !important;
    }
    .el-form {
      flex: 1;
    }
  }
  .el-table {
    margin: 12px;
  }
  .el-table__header-wrapper {
    background-color: #f0f4fb;
    border-radius: 8px;
    overflow: hidden;
  }
  .has-gutter,
  .el-table__fixed-header-wrapper {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    background: #f0f4fb;
    tr {
      background-color: #f0f4fb;
      border-radius: 8px;
      overflow: hidden;
    }
    th {
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }
  .el-table__row {
    .cell {
      color: #333;
      font-size: 14px;
    }
  }
  .el-button--primary {
    background-color: #4278c9;
    border-color: #4278c9;
  }
  .el-button--primary.is-plain {
    color: #4278c9;
    background: rgba(66, 120, 201, 0.1);
    border-color: #4278c9;
  }
  .el-button--success {
    background-color: #00bc0d;
    border-color: #00bc0d;
  }
  .el-button--danger {
    background-color: #c94242;
    border-color: #c94242;
  }
}
</style>